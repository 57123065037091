<template>
  <div class="container">
    <div class="bg_wra">
      <div class="title">全场景管控和精细化定制</div>
      <div class="subtitle">深度满足企业数字化经营需求</div>
      <a-button type="primary" shape="round" size="large" class="expirence" @click="expirence">立即体验</a-button>
    </div>
    <div class="tab_con">
      <a-row type="flex" justify="space-around">
        <a-col :class="[{active:current==1},'tab_items']" @click="current=1"><img src="../assets/image/product/tab_1.png" alt=""> PMS管理系统</a-col>
        <a-col :class="[{active:current==2},'tab_items']" @click="current=2"><img src="../assets/image/product/tab_2.png" alt=""> 租客微信H5</a-col>
        <a-col :class="[{active:current==3},'tab_items']" @click="current=3"><img src="../assets/image/product/tab_3.png" alt=""> 微信小程序</a-col>
        <a-col :class="[{active:current==4},'tab_items']" @click="current=4"><img src="../assets/image/product/tab_4.png" alt=""> PC官网</a-col>
      </a-row>
    </div>
    <div class="content">
      <div v-if="current==1">
        <div class="title">PMS管理系统</div>
        <div class="subtitle">为您筑造智能化居住生活管理方式</div>
        <img src="../assets/image/product/pms_1.png" width="730" alt="" style="margin: 25px 0;">
        <div class="title">PMS系统平台核心功能</div>
        <a-row type="flex" align="middle" style="margin: 20px 0 70px;">
          <a-col :flex="2" style="text-align: left"><img src="../assets/image/product/pms_7.png" width="688" alt=""></a-col>
          <a-col :flex="1" class="func_list">
            <ul>
              <li>工作台</li>
              <li>客户资源</li>
              <li>房态管理</li>
              <li>合同管理</li>
              <li>营销活动</li>
              <li>财务管理</li>
              <li>综合报表</li>
              <li>权限管理</li>
              <li>短租平台</li>
              <li>服务管理</li>
            </ul>
          </a-col>
        </a-row>
        <div class="title">系统优势与亮点</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">多渠道展示</div>
            <div class="info_s">租客端APP、官网、小程序等多种渠道房源展示，深度专业闭环在 线找房、在线签约、在线支付、在线维修等租房业务流程。</div>
          </a-col>
          <a-col><img src="../assets/image/product/pms_2.png" width="670" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/pms_4.png" class="img_s" width="482" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">智慧租房硬件生态</div>
            <div class="info_s">全场景智慧交互升级，通过对接主流 IOT智能设备，实现智能门锁、水电能耗 动态管控和预警，打造差异化租住体验。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">在线签约</div>
            <div class="info_s">集成电子签名系统。通过 实名认证、云端证书、公证保全措施，保障合同签署合法有效；提供金融级别的、 全面安全保障，确保合同无法篡改。</div>
          </a-col>
          <a-col><img src="../assets/image/product/pms_3.png" class="img_s" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/pms_5.png" width="663" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">OTA房源一键发送</div>
            <div class="info_s">完善的线上OTA渠道对接，将房源信息通过各种渠道分发，并高效统一管理，降低营销人力成本，提高营销转化率和 效率。多渠道精准分发，快速触达更多目标用户。 多个线上渠道，目前主要与：支付宝、贝壳、房多多、一带科技、蘑菇租房、京东租房、诸葛找房、巴乐兔、闲鱼 等 多种渠道合作，让房屋更加有效曝光。</div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">高度可配置化</div>
            <div class="info_s">寓信PMS具有很强的扩展性、兼容性，支持自定义配置与开放接口，可适应一切复杂的业务运营流程。 审批流程、报表、权限（功能权限与数据权限）等均可通过简单自定义配置，同时可以和第三方系统对接满足不同长租公寓的个性化需求；更适用大企业集团长租公寓业务和使用场景。</div>
          </a-col>
          <a-col><img src="../assets/image/product/pms_6.png" width="663" alt=""></a-col>
        </a-row>
      </div>
      <div v-if="current==2">
        <div class="title">租客微信H5</div>
        <div class="subtitle">给每个公众号都装上强大的品牌店铺</div>
        <img src="../assets/image/product/weixin_1.png" width="645" alt="" style="margin: 15px 0;">
        <div class="title">帮您在微信公众号内搭建移动展示平台</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">可无缝嵌入公众号任何位置</div>
            <div class="info_s">首页菜单栏、图文内嵌、欢迎语、关键词回复推送等位置均可展现课堂入口，方便用户快速找到</div>
          </a-col>
          <a-col><img src="../assets/image/product/weixin_2.png" width="636" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/weixin_3.png" width="548" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">一键转发 让房源在微信中自由传播</div>
            <div class="info_s">房源店铺可分享至微信朋友圈、社群等位置，便于用户分享，有助于店铺获取新的流量</div>
          </a-col>
        </a-row>
      </div>
      <div v-if="current==3">
        <div class="title">租客小程序</div>
        <div class="subtitle">给每家品牌公寓都配上专属小程序</div>
        <img src="../assets/image/product/xiaochengxu_1.png" width="680" alt="" style="margin: 20px 0;">
        <div class="title">从获客、签约到租后，用小程序构建移动服务闭环</div>
        <a-row type="flex" align="middle">
          <a-col flex="auto">
            <div class="info_l">轻松找到房源入口 创造优质租住体验</div>
            <div class="info_s" style="width: 300px;">微信首页任务栏等60+小程序入口，让10亿用户轻松发现你</div>
          </a-col>
          <a-col><img src="../assets/image/product/xiaochengxu_2.png" width="516" alt=""></a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto" style="text-align: left"><img src="../assets/image/product/xiaochengxu_3.png" class="img_s2" width="525" alt=""></a-col>
          <a-col class="order">
            <div class="info_l">不止微信小程序</div>
            <div class="info_l">更多超级生态正在打通</div>
            <div class="info_s">覆盖微信、百度等多个超强流量生态， 帮助客户降低获客门槛，提高品牌影响力</div>
          </a-col>
        </a-row>
      </div>
      <div v-if="current==4">
        <div class="title">PC端独立官网</div>
        <div class="subtitle">不止是网站，更是你的互联网品牌名片</div>
        <img src="../assets/image/product/guanwang_1.png" alt="" width="680" style="margin: 20px 0;">
        <a-row type="flex" justify="space-between">
          <a-col class="col-item">
            <img src="../assets/image/product/guanwang_icon1.png" alt="">
            <p>沉浸式观看</p>
            <p>优化找房体验</p>
          </a-col>
          <a-col class="col-item">
            <img src="../assets/image/product/guanwang_icon2.png" alt="">
            <p>个性化官网</p>
            <p>拓展获客渠道</p>
          </a-col>
          <a-col class="col-item">
            <img src="../assets/image/product/guanwang_icon3.png" alt="">
            <p>自定义域名</p>
            <p>提升品牌形象</p>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" style="margin-top: 30px;">
          <a-col flex="auto">
            <div class="info_l">自定义域名 提升品牌形象</div>
            <div class="info_s">支持域名自定义链接，打造独立的品牌形象</div>
          </a-col>
          <a-col><img src="../assets/image/product/guanwang_2.png" width="690" alt=""></a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductService",
  data(){
    return{
      current:1
    }
  },
  methods:{
    expirence(){
      window.open('https://saas.yxpms.com')
    }
  },
  created() {
    if(this.$route.params.key){
      this.current=this.$route.params.key
    }
  }
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  text-align: center;
  color: #fff;
  background: url("../assets/image/bg_product.png") no-repeat center;
  background-size: cover;
  .title{
    font-size: 60px;
    padding-top: 120px;
  }
  .subtitle{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .expirence{
    width: 180px;
  }
}
.tab_con{
  width: 1200px;
  height: 120px;
  background-color: #fff;
  margin: -60px auto 0;
  box-shadow: 0px 7px 12px 4px rgba(225, 225, 225, 0.5);
  border-radius: 8px;
  .tab_items{
    height: 118px;
    line-height: 118px;
    font-size: 20px;
    border-bottom: solid 2px transparent;
    cursor: pointer;
    &.active{
      border-bottom-color: #0167FF;
    }
    &:hover{
      border-bottom-color: #0167FF;
    }
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  .title{
    font-size: 32px;
    margin-bottom: 20px;
  }
  .subtitle{
    font-size: 16px;
  }
  .info_l{
    max-width: 426px;
    text-align: left;
    font-size: 28px;
  }
  .info_s{
    max-width: 426px;
    text-align: left;
    font-size: 16px;
    color: #666;
    margin-top: 10px;
  }
  .col-item{
    width: 368px;
    height: 260px;
    box-shadow: 0px 5px 20px 9px #F3F3F3;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      margin-bottom: 30px;
    }
    p{
      font-size: 20px;
      color: #666;
      margin: 0;
    }
  }
  ul{
    padding: 70px 0 0;
    overflow: hidden;
    width: 400px;
    li{
      list-style: none;
      float: left;
      width: 50%;
      text-align: left;
      padding-left: 27px;
      margin-top: 50px;
      font-size: 16px;
      &:before{
        display: inline-block;
        content: "";
        width: 30px;
        height: 30px;
        background-image: url("../assets/image/jingling.png");
        vertical-align: middle;
        margin-right: 20px;
      }
      &:nth-child(1):before{
        background-position: 0px 0px;
      }
      &:nth-child(2):before{
        background-position: -134px 0px;
      }
      &:nth-child(3):before{
        background-position: 0px -82px;
      }
      &:nth-child(4):before{
        background-position: -134px -81px;
      }
      &:nth-child(5):before{
        background-position: -1px -163px;
      }
      &:nth-child(6):before{
        background-position: -134px -162px;
      }
      &:nth-child(7):before{
        background-position: -1px -243px;
      }
      &:nth-child(8):before{
        background-position: -134px -242px;
      }
      &:nth-child(9):before{
        background-position: -1px -323px;
      }
      &:nth-child(10):before{
        background-position: -134px -322px;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .bg_wra{
    height: 300px;
    background-position: top;
    .title{
      font-size: 28px;
    }
    .subtitle{
      font-size: 18px;
    }
    .expirence{
      display: none;
    }
  }
  .tab_con{
    width: 100%;
    height: 60px;
    margin-top: -30px;
    .tab_items{
      font-size: 12px;
      height: 58px;
      line-height: 58px;
      img{
        height: 16px;
      }
    }
  }
  .content{
    width: 100%;
    padding: 40px 25px;
    .title{
      font-size: 22px;
    }
    .info_l{
      max-width: 100%;
      font-size: 20px;
      text-align: center;
    }
    .info_s{
      max-width: 100%;
      font-size: 14px;
    }
    .order{
      order: -1;
    }
    img{
      width: 100%;
    }
    .img_s{
      width: 80%;
      margin-top: 30px;
    }
    .img_s2{
      width: 90%;
      margin: 30px 5% 0;
    }
    .func_list{
      height: 380px;
    }
    ul{
      padding-top: 0;
      width: 100%;
      transform: scale(.9);
    }
    .col-item{
      width: 33.3%;
      p{
        font-size: 14px;
      }
      img{
        width: 50px;
      }
    }
  }
}
</style>